<template>
    <div class="box" ref="box">
      <h1 class="h1_title">标杆项目</h1>
      <div class="nav">
        <nav v-for="item in datas" :key="item.id" @click="pusharticle(item)">
          <div class="top"><img :src="baseUrl+item.thumb" alt=""></div>
          <div class="bottom">
            <section>
              <img src="@/assets/Zhaosheng.png" alt="" class="left_img">
              <div class="right_text">{{item.description}}</div>
            </section>
          </div>
        </nav>
      </div>
       <!-- 底部分页,-->
      <div class="pagination">
        <Pagination :total="from.total" :page="from.page" :limit="from.limit" @pageChange="pageChanges" />
      </div>
    </div>
    <signUp class="signUp"></signUp>
</template>

<script setup>
import signUp from '@/components/phonesignUp.vue'
import Pagination from '@/components/Pagination.vue'
import {ref,onMounted, reactive, nextTick} from 'vue'
import { useRouter,useRoute, } from 'vue-router'
import base64_ from '@/hooks/useBase64text.js'
import { get_article_list } from '@/api/API.js'

const baseUrl = process.env.VUE_APP_BASE_URL + '/'
const router = useRouter()
const route = useRoute()
let routeData = JSON.parse(base64_.decode(route.params.obj))

let from = reactive({
  total:0,
  page:1,
  limit:10,
  type_id:routeData.type_id
})
let datas = ref('')
let box = ref("box")
let getBgxmlist = async (obj) => {
  const {data:res} = await get_article_list(obj)
  if(res.code == 200){
    from.total = res.data.total
    datas.value = res.data.data
    setTimeout(() => {
    box.value.style.transform = 'translateY(0)'
    box.value.style.opacity = '1'
    },500)
  }
  
}
onMounted(() => {
  // from.type_id = routeData.type_id
  getBgxmlist(from)
})
// 分页
let pageChanges = (e) => {
  from.page = e
  getBgxmlist(from)
}
const pusharticle = (item) => {
  router.push({
    name:"Article_xq_time",
    params:{obj:base64_.encode(JSON.stringify({
      article_wz_id:item.id,
      title1:routeData.title1,
      title2:item.type_name,
      id:routeData.id,
      type_id:item.id,
      index:0,
      isBanner_nav_id:item.id,
    }))}
  })
}
</script>

<style lang="less" scoped>
@maxw:128rem;
.box{
  margin: 0 20/@maxw;
  transform: translateY(800px);
  opacity: 0;
  transition: ease .8s;
  width: 100%;
   .h1_title{
    box-sizing: border-box;
    font-size: 39/@maxw;
    font-family: fantBold;
    font-weight: 300;
    color: #174994;
    border-bottom:2/@maxw solid #174994;
    margin: 0;
    padding: 0 0 30/@maxw 0;
  }
  .nav{
    margin-top: 46/@maxw;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    >nav{
      //width: 539/@maxw;
      cursor: pointer;
      width: 530/@maxw;
      height: 534/@maxw;
      margin-bottom: 46/@maxw;
      -moz-box-shadow: 0px 12px 12px 0px #70707085;
      -webkit-box-shadow: 0px 12px 12px 0px #70707085;
      box-shadow: 0px 12px 12px 0px #70707085;
      >.top{
        width: 100%;
        height: 282/@maxw;
        overflow: hidden;
        img{width: 100%;height: 100%;vertical-align: middle;transition: ease .3s;}
      }
      .bottom{
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        width: 100%;
        height: 256/@maxw;
        background-image: url("../../../assets/bgxmbackground.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        >section{
          display: flex;
          justify-content: space-between;
          width: 473/@maxw;
          height: 122/@maxw;
          .left_img{
            width: 150/@maxw;
            height: 100%;
          }
          .right_text{
            flex: 1;
            height: 100%;
            margin-left: 21/@maxw;
            font-size: 18/@maxw;
            line-height: 30/@maxw;
          }
        }
      }
    }
    nav:hover{
    .top{
      img{
        transform: scale(1.05);
      }
    }
  }
  }
}
 .pagination{
  // margin: 40/@maxw 0 0 40%; 
  display: flex;
  justify-content: center;
  margin: 8% 0 0 0; 
}
@media screen and (max-width:750px) {
  @maxw_:750/100vw;
  .signUp{
    display: none;
  }
  .box{
    .h1_title{
    box-sizing: border-box;
    font-size: 39/@maxw_;
    font-family: fantBold;
    font-weight: 300;
    color: #174994;
    border-bottom:2/@maxw_ solid #174994;
    margin: 0;
    padding: 0 0 30/@maxw_ 0;
  }
  .nav{
    margin-top: 46/@maxw_;
    
      display: flex;
      flex-direction: column;
      align-items: center;
    >nav{
      width: 530/@maxw_;
      height: 534/@maxw_;
      margin-bottom: 50/@maxw_;
      >.top{
        height: 282/@maxw_;
      }
      .bottom{
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        width: 100%;
        height: 256/@maxw_;
        background-image: url("../../../assets/bgxmbackground.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        >section{
          display: flex;
          justify-content: space-between;
          width: 473/@maxw_;
          height: 122/@maxw_;
          .left_img{
            width: 150/@maxw_;
            height: 100%;
          }
          .right_text{
            flex: 1;
            height: 100%;
            margin-left: 21/@maxw_;
            font-size: 25/@maxw_;
            line-height: 30/@maxw_;
             overflow:hidden;
            text-overflow:ellipsis;
            display:-webkit-box;
            -webkit-box-orient:vertical;
            -webkit-line-clamp:4; // 想要超出三行显示 就把这里改成3就好了
          }
        }
      }
    }
  }
  }
}
</style>